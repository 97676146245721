import { Controller } from 'stimulus'
import application from 'controllers'

application.register('audit--connections',
  class extends Controller {
    static targets = ['filter', 'modalDetails', 'portWrapper']

    connect() {
      this.handleKeydown = this.closeHandler.bind(this)
      document.addEventListener('keydown', this.handleKeydown)
    }

    disconnect() {
      document.removeEventListener('keydown', this.handleKeydown)
    }

    showDetails(event) {
      this.clearDetailsWrapper();
      
      const connectionDetails = JSON.parse(event.currentTarget.dataset.connectionDetails);
      
      const container = document.createElement('div');
      container.classList.add('bg-gray-50', 'border', 'rounded');
      
      const list = document.createElement('ul');
      list.classList.add('divide-y-2', 'divide-gray-100', 'divide-solid');
      
      Object.entries(connectionDetails).forEach(([key, value]) => {
        if (key === 'ports' && connectionDetails.vpn === 'l2') return;
        
        const listItem = document.createElement('li');
        listItem.classList.add('p-2', 'flex', 'justify-between', 'font-medium');
        listItem.innerHTML = `
          <span class="font-medium">${key}</span>
          <span>${value}</span>
        `;
        
        list.appendChild(listItem);
      });
      
      container.appendChild(list);
      this.portWrapperTarget.appendChild(container);
      this.modalDetailsTarget.classList.remove('hidden');
    }

    closeDetails() {
      this.clearDetailsWrapper()
      this.modalDetailsTarget.classList.add('hidden')
    }

    toggleFilters() {
      this.filterTarget.classList.toggle('hidden')
    }

    closeFilters() {
      this.filterTarget.classList.add('hidden')
    }

    clearDetailsWrapper() {
      this.portWrapperTarget.innerHTML = ''
    }

    closeHandler(event) {
      if (event.key === 'Escape') {
        this.closeFilters()
        this.closeDetails()
      }
    }
  }
)
